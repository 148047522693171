"use client"

import { Session } from "next-auth"
import { SessionProvider as Provider } from "next-auth/react"

type Props = {
  children: React.ReactNode
  session: Session | null
}

export function SessionProvider({ children, session }: Props) {
  return <Provider
    // session={session}
    // Default base path if your app lives at the root "/"
    // basePath="/"
    // Re-fetch session every 5 minutes
    refetchInterval={5 * 60}
    // Re-fetches session when window is focused
    refetchOnWindowFocus={false}>{children}</Provider>
}
