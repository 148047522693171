"use client"

import React from 'react'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'

import { Modal, Typography, Descriptions, Divider, Select, Button } from 'antd'
import type { DescriptionsProps } from 'antd'
import toast, { Toaster } from 'react-hot-toast'

import { useAppDispatch } from '@/redux/store'
import { postCheckCostCenter } from '@/redux/slices/awsSlice'

import { INF_CHECK_COST_CENTER_REQ, REQUESTOR_WF, INF_CHECK_COST_CENTER_RES } from '@/models/aws.model'
import { selectList } from '@/models/ve.model'

type Props = {
  stateOpen: boolean
  onModelClose: () => void
}

const { Title } = Typography

const ModelCostCenter = ({ stateOpen, onModelClose }: Props) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { data: session } = useSession()

  const [loadData, setLoadData] = React.useState<boolean>(false)
  const [loadDataBtn, setLoadDataBtn] = React.useState<boolean>(false)
  const [requestorData, setRequestorData] = React.useState<REQUESTOR_WF | undefined>(undefined)
  const [requestorDesc, setRequestorDesc] = React.useState<DescriptionsProps['items'] | undefined>(undefined)
  const [listDeptSource, setListDeptSource] = React.useState<selectList[] | undefined>(undefined)

  // Handle modal close
  const handleModelClose = async () => {
    onModelClose()
  }

  // Handle change Cost Center
  const handleChangeCostCenter = async (value: string) => {
    let cc = ""
    let compcode = ""
    let dp = ""
    let dv = ""
    let sg = ""

    const orgName = requestorData?.cost_centers.filter(d => value == d.cost_center_name)
    console.log(orgName, orgName?.length)

    let arrSG: string[] = []
    orgName![0].sales_groups.map(d => {
      if (d.sales_group_name != "") {
        arrSG.push(d.sales_group_name)
      }
    })

    cc = value
    compcode = orgName?.length! > 0 ? orgName![0].comp_code : ""
    dp = orgName?.length! > 0 ? orgName![0].department : value
    dv = orgName?.length! > 0 ? orgName![0].division : ""
    sg = arrSG.length > 0 ? arrSG.join(",") : "N"

    await localStorage.setItem('isz_comp_code', compcode) // comp code
    await localStorage.setItem('isz_cost_center', cc) // cost_center
    await localStorage.setItem('isz_department', dp) // cost_center_name
    await localStorage.setItem('isz_division', dv) // division
    await localStorage.setItem('isz_sales_group', sg) // department_code

    const items: DescriptionsProps['items'] = [
      {
        key: '1',
        label: 'BU',
        children: requestorData?.bu
      }, {
        key: '2',
        label: 'Sub BU',
        children: requestorData?.sub_bu,
      }, {
        key: '3',
        label: 'Busines Group',
        children: requestorData?.business_group,
      }, {
        key: '4',
        label: 'Name',
        span: 2,
        children: `${requestorData?.prefix} ${requestorData?.name} ${requestorData?.lastname}`,
      }, {
        key: '5',
        label: 'Email',
        children: requestorData?.email,
      }, {
        key: '6',
        label: 'Comp Code',
        children: compcode,
      }, {
        key: '7',
        label: 'Cost Center',
        span: 2,
        children: cc,
      }, {
        key: '8',
        label: 'Department',
        span: 3,
        children: dp,
      }, {
        key: '9',
        label: 'Division',
        span: 3,
        children: dv,
      }, {
        key: '10',
        label: 'Sales Group (If any)',
        children: sg,
      },
    ]

    await setRequestorDesc(items)
    router.refresh()
  }

  const handleLoadData = async () => {
    await setLoadDataBtn(true)
    console.log("load Data")

    const data: INF_CHECK_COST_CENTER_REQ = {
      requestor: session?.user?.email!
    }

    console.log(data)

    await dispatch(postCheckCostCenter(data)).then(async (res) => {
      if (res.meta.requestStatus === "rejected") {
        toast.error('Failed: Please try again later.')
      } else {
        const resData: INF_CHECK_COST_CENTER_RES = res.payload as INF_CHECK_COST_CENTER_RES
        const requestors = resData.result.data.requestors
        console.log(resData)

        if (requestors.length > 0) {
          let isz_comp_code = localStorage.getItem("isz_comp_code") || ""
          let isz_cost_center = localStorage.getItem("isz_cost_center") || ""
          let isz_department = localStorage.getItem("isz_department") || ""
          let isz_division = localStorage.getItem("isz_division") || ""
          let isz_sales_group = localStorage.getItem("isz_sales_group") || ""

          requestors.filter(d => d.email === session?.user?.email)

          requestors.map(async d => {
            if (d.email === session?.user?.email) {
              await setRequestorData(d)

              const items: DescriptionsProps['items'] = [
                {
                  key: '1',
                  label: 'BU',
                  children: d.bu,
                }, {
                  key: '2',
                  label: 'Sub BU',
                  children: d.sub_bu,
                }, {
                  key: '3',
                  label: 'Busines Group',
                  children: d.business_group,
                }, {
                  key: '4',
                  label: 'Name',
                  span: 2,
                  children: `${d.prefix} ${d.name} ${d.lastname}`,
                }, {
                  key: '5',
                  label: 'Email',
                  children: d.email,
                }, {
                  key: '6',
                  label: 'Comp Code',
                  children: isz_comp_code,
                }, {
                  key: '7',
                  label: 'Cost Center',
                  span: 2,
                  children: isz_cost_center,
                }, {
                  key: '8',
                  label: 'Department',
                  span: 3,
                  children: isz_department,
                }, {
                  key: '9',
                  label: 'Division',
                  span: 3,
                  children: isz_division,
                }, {
                  key: '10',
                  label: 'Sales Group (If any)',
                  children: isz_sales_group,
                }
              ]

              let costCenterName: selectList[] = []

              d.cost_centers.map(async t => {
                costCenterName.push({ "label": t.department, "value": t.cost_center_name, "key": `cost-center-${t.cost_center_name}` })
              })
              await setListDeptSource(costCenterName)


              await setRequestorDesc(items)
            }
          })
        }
      }

      await setLoadData(true)
      await setLoadDataBtn(false)
    }).catch((err) => {
      console.log(err)
      throw err
    })
  }

  return <>
    <Modal title="Choose your Department" width={640} open={stateOpen} onCancel={handleModelClose} footer={[<Button key="close-costcenter-modal" size="large" type='primary' danger onClick={handleModelClose}>Close</Button>]}>
      {loadData && <>
        <Divider />
        <Descriptions title="User Info" items={requestorDesc} />
        <Divider />
        <Title level={5}>Department</Title>

        <Select
          size='large'
          style={{ width: '100%' }}
          onChange={handleChangeCostCenter}
          options={listDeptSource}
        />
      </>}

      {!loadData && <div className='align-center'><Button className='color-primary' size='large' loading={loadDataBtn} onClick={handleLoadData}>Load Data</Button></div>}
    </Modal>

    <Toaster />
  </>
}

export default ModelCostCenter
