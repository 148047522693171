import(/* webpackMode: "eager" */ "/app/app/globals.css");
import(/* webpackMode: "eager" */ "/app/assets/css/fontawesome.css");
import(/* webpackMode: "eager" */ "/app/assets/css/brands.css");
import(/* webpackMode: "eager" */ "/app/assets/css/solid.css");
import(/* webpackMode: "eager" */ "/app/components/ComponentFooter.tsx");
import(/* webpackMode: "eager" */ "/app/components/ComponentHeader.tsx");
import(/* webpackMode: "eager" */ "/app/components/ComponentSider.tsx");
import(/* webpackMode: "eager" */ "/app/components/SessionProvider.tsx");
import(/* webpackMode: "eager" */ "/app/components/ViewLogin.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/antd/lib/layout/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/app/redux/provider.tsx")