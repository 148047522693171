"use client"

import React from 'react'
import { useRouter } from 'next/navigation'

import type { MenuProps } from 'antd'
import { Layout, Menu, theme } from 'antd'
import { DashboardOutlined, TeamOutlined, TransactionOutlined, AreaChartOutlined } from '@ant-design/icons'

const { Sider } = Layout

type Props = {}

const menuItem: MenuProps['items'] = [
  { key: 'menu-dashboard', icon: React.createElement(DashboardOutlined), label: 'Dashboard' },
  { key: 'menu-evaluation', icon: React.createElement(TransactionOutlined), label: 'Your Evaluation' },
  { key: 'menu-transaction', icon: React.createElement(TransactionOutlined), label: 'Transaction', children: [{ key: 'submenu-transaction-SAP', icon: React.createElement(TransactionOutlined), label: 'SAP' }, { key: 'submenu-transaction-AP', icon: React.createElement(TransactionOutlined), label: 'Accounts Payable (AP)' }, { key: 'submenu-transaction-Netsuite', icon: React.createElement(TransactionOutlined), label: 'Netsuite' }, { key: 'submenu-transaction-VPAY', icon: React.createElement(TransactionOutlined), label: 'VPay' }, { key: 'submenu-transaction-AllPay', icon: React.createElement(TransactionOutlined), label: 'AllPay' }] },
  { key: 'menu-vendor', icon: React.createElement(TeamOutlined), label: 'Vendor', children: [{ key: 'submenu-vendor-summary', icon: React.createElement(DashboardOutlined), label: 'Summary' }, { key: 'submenu-vendor-list', icon: React.createElement(TeamOutlined), label: 'Vendor' }] },
  { key: 'menu-report', icon: React.createElement(AreaChartOutlined), label: 'Report' }
]

const ComponentSider = (props: Props) => {
  const router = useRouter()
  const { token: { colorBgContainer } } = theme.useToken()

  const onClickMenu: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case "menu-dashboard":
        router.push("/dashboard")
        break;
      case "menu-evaluation":
        router.push("/evaluation")
        break;
      case "menu-transaction":
        router.push("/transaction")
        break;
      case "submenu-transaction-AP":
        router.push("/transaction/ap")
        break;
      case "submenu-transaction-SAP":
        router.push("/transaction/sap")
        break;
      case "submenu-transaction-Netsuite":
        router.push("/transaction/netsuite")
        break;
      case "submenu-transaction-VPAY":
        router.push("/transaction/vpay")
        break;
      case "submenu-transaction-AllPay":
        router.push("/transaction/allpay")
        break;
      case "submenu-report-summary":
        router.push("/report/summary")
        break;
      case "menu-report":
        router.push("/report")
        break;
      case "submenu-vendor-summary":
        router.push("/vendor/summary")
        break;
      case "submenu-vendor-list":
        router.push("/vendor/list")
        break;
      default:
        router.push("/dashboard")
        break;
    }
  }

  return (
    <Sider width={250} breakpoint={"sm"} theme={"light"}>
      <Menu
        theme='dark'
        onClick={onClickMenu}
        mode="inline"
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        style={{ height: '100%', borderRight: 0 }}
        items={menuItem}
      />
    </Sider>
  )
}

export default ComponentSider
